import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MaterialTable from "material-table";
import tableIcons, {
  options,
} from "../../../components/universal/table_attributes";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import AnnounceIcon from "@material-ui/icons/AddAlert";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import DataServices from "../../../lib/services/api/data_services";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import ManageUsers from "../manage_user";
import Button from "@material-ui/core/Button";
import useDebounce from "../../../lib/utils/debounce";
import NotificationMessage from "../notification_trigger";
import SettingsIcon from "@material-ui/icons/Settings";
import UserEvolutionButtons from "./evolution_buttons";
import axios from "axios";
import { BASE_API_URL } from "../../../lib/services/constants";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AllGroupUserNotificationMessage from "../notification_all_group_users";
import AssignGroupModal from "../AssignGroupModal";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "90%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

const UserListing = (props) => {
  const location = useLocation();

  const classes = useStyles();
  const token = sessionStorage.getItem("token") ?? null;
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  const [users, setUsers] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupID, setGroupID] = useState(null);
  const [freeGroupList, setFreeGroupList] = useState([]);
  const [freeGroupID, setFreeGroupID] = useState("");
  const groupId = props.match.params.groupId;
  const role = userDetails?.role ?? null;

  const [loading, setLoading] = useState(false);
  const [showSendNotification, setShowSendNotification] = useState(false);
  const [showSendNotificationToAll, setShowSendNotificationToAll] =
    useState(false);
  const [action, setAction] = useState(null);
  const [currentSelection, setCurrentSelection] = useState({});
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const debouncedSearch = useDebounce(searchText, 700);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteUserID, setDeleteUserID] = useState(null);
  const [assignModal, setAssignModal] = useState(false);
  const [assignGroupModal, setAssigGroupnModal] = useState(false);

  const tableOptions = {
    ...options,
    page: page || 1,
    total: total,
    pageSize: pageSize,
    searchText: searchText,
  };

  const message = useContext(ToastMessageContext);

  const getUsers = () => {
    setLoading(true);
    const params = {
      page: page + 1,
      limit: pageSize,
      search_keyword: debouncedSearch,
      user_group: groupId,
      role: role,
    };
    DataServices.getData(params, "/admin/users").then((res) => {
      if (res.success) {
        setUsers(res.users);
        setTotal(+res.total);
        setLoading(false);
      } else {
        message.showToastMessage({
          message: "Something went wrong. Try Again",
          variant: "error",
        });
        setLoading(false);
      }
    });
  };

  const getGroupList = () => {
    const params = {};
    DataServices.getData(params, "/admin/user_groups").then((res) => {
      if (res.success) {
        setGroupList(res.user_groups);
      }
    });
  };

  const getFreeGroupList = () => {
    const params = {
      group_id: 0,
    };
    DataServices.postData(params, "/admin/ungroup-users").then((res) => {
      if (res.success) {
        setFreeGroupList(res.users);
      }
    });
  };

  const assignFreeUser = () => {
    const params = {
      group_id: groupId,
      user_id: freeGroupID,
    };
    DataServices.postData(params, "/admin/user-group-change").then((res) => {
      if (res.success) {
        getFreeGroupList();
        getUsers();
      }
    });
  };

  useEffect(() => {
    getGroupList();
    getFreeGroupList();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, pageSize, debouncedSearch]);

  const genderMap = { 0: "Female", 1: "Male", 2: "Others" };
  const verifiedMap = { 0: "Pending", 1: "Verified" };

  const userBlock = async (id) => {
    try {
      const response = await axios(BASE_API_URL + "/user/status-update", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: id,
        },
      });
      if (response.data?.success == true) {
        getUsers();
        message.showToastMessage({
          message: "User update successfully",
          variant: "success",
        });
      } else {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const userGroupUpdate = async (user_id, group_id) => {
    try {
      setOpenModal(false);
      const response = await axios.post(
        BASE_API_URL + "/admin/user-group-change",
        {
          user_id: user_id,
          group_id: group_id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data?.success == true) {
        getUsers();
        message.showToastMessage({
          message: "User group update successfully",
          variant: "success",
        });
      } else {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.post(
        BASE_API_URL + "/admin/delete-user/" + deleteUserID,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setOpen(false);
      setLoading(true);
      if (response.data?.success == true) {
        getUsers();
        message.showToastMessage({
          message: "User delete successfully",
          variant: "success",
        });
      } else {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeAdminAction = (id) => {
    setLoading(true);
    let formData = {
      id: id,
    };
    DataServices.postData(formData, "/admin/remove-admin").then((res) => {
      if (res.success) {
        getUsers();
        message.showToastMessage({
          message: "Remove user Successfully",
          variant: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
        message.showToastMessage({
          message: res.error ? res.error : "Something went wrong. Try again",
          variant: "error",
        });
      }
    });
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <span style={{ paddingLeft: "3%", whiteSpace: "pre" }}>
          {rowData.name || "-"}
        </span>
      ),
    },
    {
      title: "User Email",
      field: "email",
      render: (rowData) => <span>{rowData.email || "-"}</span>,
    },
    {
      title: "Age",
      field: "age",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Evolution",
      field: "current_evolution",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Gender",
      field: "gender",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <span>{rowData.gender ? genderMap[rowData.gender] : "N/A"}</span>
      ),
    },
    {
      title: "Zipcode",
      field: "zipcode",
      render: (rowData) => (
        <span style={{ textTransform: "uppercase" }}>
          {rowData.zipcode || "-"}
        </span>
      ),
    },
    {
      title: "Role",
      field: "role",
      render: (rowData) => (
        <span style={{ textTransform: "capitalize" }}>
          {rowData.role.replace("_", " ") || "-"}
        </span>
      ),
    },
    {
      title: "Created At",
      field: "created_at",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <span>
          {moment(rowData.created_at).format("MM/DD/YYYY hh:mm A") || "-"}
        </span>
      ),
    },
    ...(location.pathname.includes("/users/sensamind/") &&
    role === "super_admin"
      ? [
          {
            title: "Action",
            field: "action",
            render: (rowData) => (
              <Button
                component={"div"}
                size={"small"}
                variant={"outlined"}
                className={"user_block"} //user_block
                onClick={() => removeAdminAction(rowData.id)}
                style={{ whiteSpace: "pre" }}
              >
                Remove Member
              </Button>
            ),
          },
        ]
      : []),
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <Button
          component={"div"}
          size={"small"}
          variant={"outlined"}
          className={rowData.status == 1 ? "user_block" : "user_unblock"}
          onClick={() => userBlock(rowData.id)}
        >
          {rowData.status == 1 ? "Block" : "Unblock"}
        </Button>
      ),
    },
    {
      title: "Verified",
      field: "is_verified",
      render: (rowData) => (
        <Button
          component={"div"}
          disabled
          size={"small"}
          variant={"outlined"}
          className={rowData.is_verified === 1 ? "positive" : "negative"}
        >
          {verifiedMap[rowData.is_verified]}
        </Button>
      ),
    },
    {
      title: "Delete",
      field: "id",
      render: (rowData) => (
        <Button
          component={"div"}
          size={"small"}
          variant={"outlined"}
          className={"user_block"}
          onClick={() => userDelete(rowData.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleClose = (data) => {
    setAction(null);
    setCurrentSelection({});
    getUsers();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleChangeGroup = () => {
    userGroupUpdate(currentSelection.id, groupID);
  };

  const userDelete = (id) => {
    setOpen(true);
    setDeleteUserID(id);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleChangeUngroupUser = () => {
    assignFreeUser();
    setAssignModal(false);
  };

  const handleCloseUngroupUserModal = () => {
    setAssignModal(false);
  };

  const onSubmit = (data) => {
    setLoading(true);
    DataServices.postData(data, "/admin/assign-role").then((res) => {
      if (res.success) {
        getUsers();
        getGroupList();
        getFreeGroupList();
        setAssigGroupnModal(false);
        message.showToastMessage({
          message: "Assign user Successfully",
          variant: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
        message.showToastMessage({
          message: res.error ? res.error : "Something went wrong. Try again",
          variant: "error",
        });
      }
    });
  };

  return (
    <div className={classes.tableWrapper}>
      {/* delete modal */}
      <AssignGroupModal
        openModal={assignGroupModal}
        freeUser={freeGroupList}
        groupList={groupList}
        handleCloseModal={() => {
          setAssigGroupnModal(false);
        }}
        onSubmit={(data) => onSubmit(data)}
      />
      <Dialog
        open={open}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to delete this user permanently?"}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button className={"user_block"} onClick={handleDeleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* group change modal */}
      <Dialog
        maxWidth={"md"}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to change the group for this user?"}
        </DialogTitle>
        <DialogActions
          className="flex gap-2"
          style={{ padding: "30px", flexDirection: "column" }}
        >
          <div style={{ width: "100%" }}>
            <InputLabel style={{ paddingBottom: "10px" }} id="evolution">
              Group List:
            </InputLabel>
            <Select
              defaultValue={currentSelection?.user_group?.id}
              labelId="evolution"
              id="evolution"
              name="evolution"
              label={"evolution"}
              placeholder={"evolution"}
              onChange={(e) => {
                setGroupID(e.target.value);
              }}
              fullWidth
            >
              {groupList.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div
            style={{ padding: "10px 0 0 0", width: "100%", textAlign: "end" }}
          >
            <Button color="primary" onClick={handleCloseModal}>
              No
            </Button>
            <Button
              className={"user_block"}
              onClick={() => handleChangeGroup()}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* assign user group */}
      <Dialog
        maxWidth={"md"}
        open={assignModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to change the group for this user?"}
        </DialogTitle>
        <DialogActions
          className="flex gap-2"
          style={{ padding: "30px", flexDirection: "column" }}
        >
          <div style={{ width: "100%" }}>
            {freeGroupList.length === 0 ? (
              <>
                <InputLabel style={{ paddingBottom: "10px" }} id="ungroupfree">
                  No Free User Found
                </InputLabel>
              </>
            ) : (
              <>
                <InputLabel style={{ paddingBottom: "10px" }} id="ungroup">
                  Free User List:
                </InputLabel>
                <Select
                  labelId="ungroup"
                  id="ungroup"
                  name="ungroup"
                  label={"ungroup"}
                  placeholder={"ungroup"}
                  value={freeGroupID}
                  onChange={(e) => {
                    setFreeGroupID(e.target.value);
                  }}
                  fullWidth
                >
                  {freeGroupList.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          </div>
          <div
            style={{ padding: "10px 0 0 0", width: "100%", textAlign: "end" }}
          >
            <Button
              color="primary"
              onClick={() => handleCloseUngroupUserModal()}
            >
              No
            </Button>
            <Button
              className={"user_block"}
              onClick={() => handleChangeUngroupUser()}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* end modal */}
      <div className={"align-corner"}>
        <Typography
          component={"h2"}
          style={{ width: "50%" }}
          className={"title"}
        >
          Users
        </Typography>
        <div style={{ width: "100%", textAlign: "right", margin: "15px 0" }}>
          <IconButton onClick={() => setShowSendNotificationToAll(true)}>
            <AnnounceIcon />
          </IconButton>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ margin: "0 10px 0 0" }}
            onClick={() => setAssignModal(true)}
          >
            Assign User
          </Button>
          {location.pathname.includes("/users/sensamind/") &&
          role === "super_admin" ? (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => setAssigGroupnModal(true)}
              style={{ margin: "0 10px 0 0" }}
            >
              Assign Role
            </Button>
          ) : null}
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setAction("create")}
          >
            Add new User
          </Button>
        </div>
      </div>
      <MaterialTable
        style={{ boxShadow: "1px 0px 20px 5px #7052fb10" }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={users || []}
        isLoading={loading}
        options={tableOptions}
        localization={{
          toolbar: {
            searchPlaceholder: "Search user by email",
          },
          body: {
            emptyDataSourceMessage: "No users found",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
          header: {
            actions: "Actions",
          },
        }}
        onChangePage={(page) => {
          setPage(page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          setPageSize(pageSize);
        }}
        onSearchChange={(search) => {
          setSearchText(search);
        }}
        totalCount={+total}
        page={+page}
        actions={[
          {
            icon: () => <TransferWithinAStationIcon fontSize="small" />,
            tooltip: "Transfer",
            onClick: (event, rowData) => {
              setCurrentSelection(rowData);
              setOpenModal(true);
            },
          },
          {
            icon: () => <EditIcon fontSize="small" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setCurrentSelection(rowData);
              setAction("update");
            },
          },
          {
            icon: () => <ViewIcon fontSize="small" />,
            tooltip: "View",
            onClick: (event, rowData) => {
              props.history.push(`/users/${rowData.id}/details`);
            },
          },
          {
            icon: () => <AnnounceIcon fontSize="small" />,
            tooltip: "Notify",
            onClick: (event, rowData) => {
              setCurrentSelection(rowData);
              setShowSendNotification(true);
            },
          },
          {
            icon: () => <SettingsIcon color={"disabled"} />,
            tooltip: "Evolutions",
            onClick: (event, rowData) => {
              setCurrentSelection(rowData);
              setAction("view");
            },
          },
        ]}
      />
      <div className={classes.evolWrapper}>
        {action && action === "view" && (
          <UserEvolutionButtons
            groupId={currentSelection.id}
            groupName={currentSelection.name}
          />
        )}
      </div>
      {action && action !== "view" && (
        <ManageUsers
          action={action}
          handleClose={handleClose}
          currentSelection={currentSelection}
          role={role}
          groupId={groupId}
        />
      )}
      {showSendNotification && (
        <NotificationMessage
          topic={null}
          handleClose={() => setShowSendNotification(false)}
          currentSelection={currentSelection}
        />
      )}

      {showSendNotificationToAll && (
        <AllGroupUserNotificationMessage
          groupID={groupId}
          handleClose={() => setShowSendNotificationToAll(false)}
        />
      )}
    </div>
  );
};

export default UserListing;
