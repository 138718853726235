import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Controller, useForm } from "react-hook-form";

const AssignGroupModal = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  return (
    <Dialog
      maxWidth={"md"}
      open={props.openModal}
      onClose={props.handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Assign user to role</DialogTitle>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <DialogActions
          className="flex gap-2"
          style={{ padding: "30px", flexDirection: "column" }}
        >
          <Controller
            name="id"
            control={control}
            rules={{
              required: "User is required",
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel style={{ paddingBottom: "10px" }} id="id">
                    User List:
                  </InputLabel>
                  <Select
                    labelId="id"
                    id="id"
                    name="id"
                    label={"User"}
                    placeholder={"User"}
                    onChange={onChange}
                    value={value ?? ""}
                    fullWidth
                  >
                    {props.freeUser?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
          />
          {errors?.id?.message && (
            <p
              style={{
                color: "rgb(95, 33, 32)",
                backgroundColor: "rgb(253, 237, 237)",
                padding: "7px 20px",
                margin: "0",
                borderRadius: "5px",
              }}
            >
              {errors?.id?.message}
            </p>
          )}
          {/* group */}
          <Controller
            name="group"
            control={control}
            rules={{
              required: "User group is required",
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel
                    style={{ paddingBottom: "10px", margin: "20px 0 0 0" }}
                    id="group"
                  >
                    User Group:
                  </InputLabel>
                  <Select
                    labelId="group"
                    id="group"
                    name="group"
                    label={"Group"}
                    placeholder={"Group"}
                    onChange={onChange}
                    value={value ?? ""}
                    fullWidth
                  >
                    {props.groupList?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
          />
          {errors?.group?.message && (
            <p
              style={{
                color: "rgb(95, 33, 32)",
                backgroundColor: "rgb(253, 237, 237)",
                padding: "7px 20px",
                margin: "0",
                borderRadius: "5px",
              }}
            >
              {errors?.group?.message}
            </p>
          )}
          {/* Role */}
          <Controller
            name="role"
            control={control}
            rules={{
              required: "User role is required",
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <div style={{ width: "100%" }}>
                  <InputLabel
                    style={{ paddingBottom: "10px", margin: "20px 0 0 0" }}
                    id="role"
                  >
                    User Role:
                  </InputLabel>
                  <Select
                    labelId="role"
                    id="role"
                    name="role"
                    label={"Role"}
                    placeholder={"Role"}
                    onChange={onChange}
                    value={value ?? ""}
                    fullWidth
                  >
                    <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                  </Select>
                </div>
              </>
            )}
          />
          {errors?.id?.message && (
            <p
              style={{
                color: "rgb(95, 33, 32)",
                backgroundColor: "rgb(253, 237, 237)",
                padding: "7px 20px",
                margin: "0",
                borderRadius: "5px",
              }}
            >
              {errors?.id?.message}
            </p>
          )}
          <div
            style={{ padding: "10px 0 0 0", width: "100%", textAlign: "end" }}
          >
            <Button
              color="primary"
              onClick={props.handleCloseModal}
              type="button"
            >
              No
            </Button>
            <Button className={"user_block"} autoFocus type="submit">
              Yes
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignGroupModal;
