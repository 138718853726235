import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import tableIcons, {
  options,
} from "../../components/universal/table_attributes";
import Button from "@material-ui/core/Button";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import axios from "axios";
import useDebounce from "../../lib/utils/debounce";
import { BASE_API_URL } from "../../lib/services/constants";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "90%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

const NewUser = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [open, setOpen] = useState(false);
  const [deleteUserID, setDeleteUserID] = useState(null);
  const debouncedSearch = useDebounce(searchText, 700);
  const token = sessionStorage.getItem("token") ?? null;
  const message = useContext(ToastMessageContext);
  const genderMap = { 0: "Female", 1: "Male", 2: "Others" };

  const tableOptions = {
    ...options,
    page: page || 1,
    total: total,
    pageSize: pageSize,
    searchText: searchText,
  };

  const getApiData = async () => {
    setLoading(true);
    const params = {
      page: page + 1,
      limit: pageSize,
      search_keyword: debouncedSearch,
    };
    try {
      const response = await axios(BASE_API_URL + "/admin/users", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params,
      });
      if (response.data?.success == true) {
        setApiData(response.data.users);
        setTotal(+response.data.total);
        setLoading(false);
      } else {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getApiData();
  }, [page, pageSize, debouncedSearch]);

  const columns = [
    {
      title: "Name",
      field: "name",
      // width: "25%",
      // headerStyle: {
      //   paddingLeft: "3%",
      // },
      // cellStyle: {
      //   paddingLeft: "3%",
      // },
      render: (rowData) => (
        <span style={{ paddingLeft: "3%", whiteSpace: "pre" }}>
          {rowData.name || "-"}
        </span>
      ),
    },
    {
      title: "User Email",
      field: "email",
      // width: "30%",
      render: (rowData) => <span>{rowData.email || "-"}</span>,
    },
    {
      title: "Age",
      field: "age",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Evolution",
      field: "current_evolution",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Gender",
      field: "gender",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <span>{rowData.gender ? genderMap[rowData.gender] : "N/A"}</span>
      ),
    },
    {
      title: "Role",
      field: "role",
      render: (rowData) => (
        <span style={{ textTransform: "capitalize" }}>
          {rowData.role.replace("_", " ") || "-"}
        </span>
      ),
    },
    {
      title: "Created At",
      field: "created_at",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <span>
          {moment(rowData.created_at).format("MM/DD/YYYY hh:mm A") || "-"}
        </span>
      ),
    },
    {
      title: "User Group",
      field: "user_group",
      render: (rowData) => (
        <span style={{ textTransform: "capitalize" }}>
          {rowData.user_group?.name ? rowData.user_group?.name : "Free User"}
        </span>
      ),
    },
    {
      title: "Delete",
      field: "id",
      render: (rowData) => (
        <Button
          component={"div"}
          size={"small"}
          variant={"outlined"}
          className={"user_block"}
          onClick={() => userDelete(rowData.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const userDelete = (id) => {
    setOpen(true);
    setDeleteUserID(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.post(
        BASE_API_URL + "/admin/delete-user/" + deleteUserID,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setOpen(false);
      setLoading(true);
      console.log(response.data);
      if (response.data?.success == true) {
        getApiData();
        message.showToastMessage({
          message: "User delete successfully",
          variant: "success",
        });
      } else {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to delete this user permanently?"}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            No
          </Button>
          <Button className={"user_block"} onClick={handleDeleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.tableWrapper}>
        <div className={"align-corner"}>
          <Typography
            component={"h2"}
            style={{ width: "50%" }}
            className={"title"}
          >
            All Users List
          </Typography>
        </div>
        <MaterialTable
          style={{ boxShadow: "1px 0px 20px 5px #7052fb10" }}
          icons={tableIcons}
          title={""}
          columns={columns}
          data={apiData || []}
          isLoading={loading}
          options={tableOptions}
          localization={{
            toolbar: {
              searchPlaceholder: "Search user by email",
            },
            body: {
              emptyDataSourceMessage: "No users found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
            header: {
              actions: "Actions",
            },
          }}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            setPageSize(pageSize);
          }}
          onSearchChange={(search) => {
            setSearchText(search);
          }}
          totalCount={+total}
          page={+page}
        />
      </div>
    </div>
  );
};

export default NewUser;
