import React, { useEffect, useState } from "react";
import TimeseriesChart from "react-apexcharts";
import DataServices from "../../lib/services/api/data_services";
import moment from "moment";
import { flattenArrayOfObjectsToArray } from "../../lib/utils/common_utils";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "95%",
  },
  subtitle: {
    fontSize: "1.2rem",
    textTransform: "capitalize",
    padding: "25px 0",
  },
  divClass: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    width: "calc(100% - 40px)",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    gap: "20px",
  },
}));

function ClicksTimelineGraph(props) {
  const classes = useStyles();
  let _isMounted = false;
  const { filters, refresh, user, evaluationKey } = props;
  const [times, setTimes] = useState([]);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  let newCheckData = evaluationKey ?? 1;

  useEffect(() => {
    _isMounted = true;
    setLoading(true);
    const userId = props.userId;
    let params = {};
    if (user.role !== "user") {
      params = {
        id: userId,
        ...filters,
      };
    }

    if (newCheckData) {
      params.evolution = newCheckData;
    }
    DataServices.getData(params, "/user/clicks")
      .then((res) => {
        if (res.success && _isMounted) {
          let hash = Object.create(null),
            grouped = [];
          const clicks = res.clicks;
          clicks.forEach(function (a) {
            var key = a["clicked_at"]?.slice(0, 16);
            if (!hash[key]) {
              hash[key] = { count: 0, time: key };
              grouped.push(hash[key]);
            }
            hash[key].count = hash[key].count + 1;
          });
          const timesRaw = flattenArrayOfObjectsToArray(grouped, "time");
          let timesArr = [];
          for (let i = 0; i < timesRaw.length; i++) {
            timesArr.push(
              moment.utc(timesRaw[i]).local().format("MM/DD/YYYY hh:mm A")
            );
          }
          setTimes(
            ["01/01/2019 00:00 AM"]
              .concat(timesArr)
              .concat([
                moment().add(1, "day").local().format("MM/DD/YYYY hh:mm A"),
              ])
          );
          setValues(
            [0]
              .concat(flattenArrayOfObjectsToArray(grouped, "count"))
              .concat([0])
          );
        }
      })
      .finally(() => {
        _isMounted && setLoading(false);
      });

    return () => {
      _isMounted = false;
    };
  }, [refresh, evaluationKey]);

  if (newCheckData === 1) {
    return null;
    return (
      <>
        <div className={classes.divClass}>
          <Typography variant={"subtitle2"} color="primary">
            All Time Clicks
          </Typography>
          <table
            style={{
              width: "50%",
              borderSpacing: "0px",
              border: "1px solid #000",
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  padding: "7px 0px",
                  borderRight: "1px solid #000",
                }}
              >
                Input
              </th>
              <th
                style={{ borderBottom: "1px solid #000", padding: "7px 0px" }}
              >
                Created
              </th>
            </tr>
            {times.length !== 0 ? (
              times.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                        padding: "7px 0px",
                      }}
                    >
                      {item?.input ?? "-"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                        padding: "7px 0px",
                      }}
                    >
                      {moment(item?.created_at).format("LLL") ?? "-"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                  }}
                >
                  No data found
                </td>
              </tr>
            )}
          </table>
        </div>
      </>
    );
  }
  return (
    <div>
      <TimeseriesChart
        options={{
          chart: {
            height: 350,
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          colors: ["#7052fb"],
          xaxis: {
            type: "time",
            categories: times || [],
          },
          yaxis: {
            forceNiceScale: true,
            decimalsInFloat: 0,
          },
        }}
        series={[
          {
            name: "Clicks",
            type: "area",
            data: values || [],
          },
        ]}
        type="area"
        height={350}
      />
    </div>
  );
}

export default ClicksTimelineGraph;
