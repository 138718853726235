import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableIcons, {
  options,
} from "../../../components/universal/table_attributes";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import UsersIcon from "@material-ui/icons/People";
import DataServices from "../../../lib/services/api/data_services";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useDebounce from "../../../lib/utils/debounce";
import ManageUserGroups from "./manage_user_groups";
import IconButton from "@material-ui/core/IconButton";
import AnnounceIcon from "@material-ui/icons/AddAlert";
import NotificationMessage from "../notification_trigger";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "60%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

function UserGroupsList(props) {
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const role = userDetails?.role ?? null;
  const classes = useStyles();
  const [userGroups, setuserGroups] = useState([]);

  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [showSendNotification, setShowSendNotification] = useState(false);
  const [currentSelection, setCurrentSelection] = useState({});
  const [searchText, setSearchText] = useState();
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const debouncedSearch = useDebounce(searchText, 700);
  const [deleteUserID, setDeleteUserID] = useState(null);
  const [open, setOpen] = useState(false);
  const tableOptions = {
    ...options,
    page: page || 1,
    total: total,
    pageSize: pageSize,
    searchText: searchText,
  };

  const message = useContext(ToastMessageContext);

  const getUserGroups = () => {
    setLoading(true);
    const params = {
      page: page,
      limit: pageSize,
      search_keyword: debouncedSearch,
    };
    DataServices.getData(params, "/admin/user_groups").then((res) => {
      if (res.success) {
        setuserGroups(res.user_groups);
        setTotal(res.user_groups?.length);
        setLoading(false);
      } else {
        message.showToastMessage({
          message: "Something went wrong. Try Again",
          variant: "error",
        });
        setLoading(false);
      }
    });
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    setOpen(false);
    const params = {
      group_id: deleteUserID,
    };
    DataServices.postData(params, "/admin/delete-user-group").then((res) => {
      if (res.success) {
        getUserGroups();
        message.showToastMessage({
          message: "User group delete successfully",
          variant: "success",
        });
        setLoading(false);
      } else {
        message.showToastMessage({
          message: "Something went wrong. Try Again",
          variant: "error",
        });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getUserGroups();
  }, [page, pageSize, debouncedSearch]);

  const columns = [
    {
      title: "Group Name",
      field: "name",
      width: "25%",
      headerStyle: {
        paddingLeft: "3%",
      },
      cellStyle: {
        paddingLeft: "3%",
      },
      render: (rowData) => <span>{rowData.name || "-"}</span>,
    },
    {
      title: "Description",
      field: "description",
      width: "55%",
      render: (rowData) => <span>{rowData.description || "-"}</span>,
    },
    {
      title: "No.of Users",
      field: "users_count",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => <span>{rowData.users_count || "0"}</span>,
    },
    ...(role === "super_admin"
      ? [
          {
            title: "Delete",
            field: "id",
            render: (rowData) => (
              <Button
                component={"div"}
                size={"small"}
                variant={"outlined"}
                className={"user_block"}
                onClick={() => userDelete(rowData.id)}
              >
                Delete
              </Button>
            ),
          },
        ]
      : []),
  ];

  const userDelete = (id) => {
    setOpen(true);
    setDeleteUserID(id);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleClose = (data) => {
    setAction(null);
    setCurrentSelection({});
    getUserGroups();
  };

  return (
    <>
      <div className={classes.tableWrapper}>
        <Dialog
          open={open}
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to delete this user group permanently?"}
          </DialogTitle>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDeleteModal}>
              No
            </Button>
            <Button
              className={"user_block"}
              onClick={handleDeleteUser}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <div className={"align-corner"}>
          <Typography
            component={"h2"}
            style={{ width: "50%" }}
            className={"title"}
          >
            User Groups
          </Typography>
          {role === "super_admin" ? (
            <div
              style={{ width: "100%", textAlign: "right", margin: "15px 0" }}
            >
              <IconButton onClick={() => setShowSendNotification(true)}>
                <AnnounceIcon />
              </IconButton>
              <Button
                style={{ marginLeft: "16px" }}
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  setCurrentSelection({});
                  setAction("create");
                }}
              >
                Add New User Group
              </Button>
            </div>
          ) : null}
        </div>
        <MaterialTable
          style={{ boxShadow: "1px 0px 20px 5px #7052fb10" }}
          icons={tableIcons}
          title={""}
          columns={columns}
          data={userGroups || []}
          isLoading={loading}
          options={tableOptions}
          localization={{
            toolbar: {
              searchPlaceholder: "Search by name",
            },
            body: {
              emptyDataSourceMessage: "No user groups found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
            header: {
              actions: "Actions",
            },
          }}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            setPageSize(pageSize);
          }}
          totalCount={+total}
          page={+page}
          actions={[
            {
              icon: () => <EditIcon color={"disabled"} />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setCurrentSelection(rowData);
                setAction("update");
              },
            },
            {
              icon: () => <UsersIcon color={"disabled"} />,
              tooltip: "View Users",
              onClick: (event, rowData) => {
                props.history.push(`/users/${rowData.id}`);
              },
            },
          ]}
        />
        {action && action !== "view" && (
          <ManageUserGroups
            action={action}
            currentSelection={currentSelection}
            handleClose={handleClose}
          />
        )}
      </div>
      {/* <div className={classes.evolWrapper}>
        {action && action === 'view' && (
          <EvolutionButtons
            groupId={currentSelection.id}
            groupName={currentSelection.name}
          />
        )}
      </div> */}
      {showSendNotification && (
        <NotificationMessage
          topic={"announcement"}
          handleClose={() => setShowSendNotification(false)}
        />
      )}
    </>
  );
}

export default UserGroupsList;
