import React, { useEffect, useState } from "react";
import BarChart from "react-apexcharts";
import DataServices from "../../lib/services/api/data_services";

function CauseComparison(props) {
  let _isMounted = false;
  const { user, type, stats, statsString } = props;
  const [causeStat, setCauseStat] = useState({
    yesterday: {
      name: "Yesterday",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    today: {
      name: "Today",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    _isMounted = true;
    const userId = props.userId;
    let params = {};
    if (userId) {
      params = {
        id: userId,
      };
    }
    DataServices.getData(params, "/user").then((res) => {
      if (res.success && res.user?.button_one && _isMounted) {
        let labels = [];
        const button1 = res.user?.button_one;
        const button2 = res.user?.button_two;
        for (let key in button1) {
          if (key.indexOf("cause") > -1) {
            labels.push(button1[key]);
          }
        }
        for (let key in button2) {
          if (key.indexOf("cause") > -1) {
            labels.push(button2[key]);
          }
        }
        setCategories(labels);
      }
    });

    return () => {
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      let newStats = { ...causeStat };
      const clicks = stats.yesterday.cause_clicks;
      if (clicks && clicks.length > 0) {
        newStats = {
          ...newStats,
          yesterday: {
            ...newStats.yesterday,
            data: categories.map((cat) => {
              let clickedL = clicks.filter((clk) => clk.cause === cat);
              if (clickedL.length > 0) {
                return clickedL[0]["total"];
              } else return 0;
            }),
          },
        };
      }
      const tclicks = stats.today.cause_clicks;
      if (tclicks && tclicks.length > 0) {
        newStats = {
          ...newStats,
          today: {
            ...newStats.today,
            data: categories.map((cat) => {
              let clickedL = tclicks.filter((clk) => clk.cause === cat);
              if (clickedL.length > 0) {
                return clickedL[0]["total"];
              } else return 0;
            }),
          },
        };
      }
      setCauseStat(newStats);
    }
  }, [statsString, categories]);

  return (
    <div>
      <BarChart
        options={{
          chart: {
            type: "bar",
            width: "100%",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "bottom",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            title: {
              text: "Cause",
            },
            categories: categories || [],
          },
          yaxis: {
            title: {
              text: "No of Clicks",
            },
          },
          fill: {
            opacity: 1,
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: {
                  type: "bar",
                  width: "100%",
                  height: 650,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                legend: {
                  position: "top",
                },
                xaxis: {
                  title: {
                    text: "No of Clicks",
                  },
                },
                yaxis: {
                  title: {
                    text: "Cause",
                  },
                },
              },
            },
          ],
        }}
        series={[causeStat.yesterday, causeStat.today]}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default CauseComparison;
