import request from "./index";

function getData(params, url) {
  return request({
    url: url,
    method: "GET",
    params: params,
  });
}

function postData(formData, url) {
  return request({
    url: url,
    method: "POST",
    data: formData,
  });
}

function updateData(formData, url) {
  return request({
    url: url,
    method: "PUT",
    data: formData,
  });
}

const DataServices = {
  getData,
  postData,
  updateData,
};

export default DataServices;
