import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserGreetingMessage from "./greeting_message";
import { makeStyles } from "@material-ui/core/styles";
import ButtonClickStats from "./button_click_stats";
import CauseClickStats from "./causes_clicks_stats";
import ClicksTimelineGraph from "./clicks_timeline_graph";
import DataServices from "../../lib/services/api/data_services";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import CircularProgress from "@material-ui/core/CircularProgress";
import CumulativeStats from "./cummulative_stats";
import CauseComparison from "./cause_comparison_graph";
import ClicksRawData from "./clicks_raw_data";
import FilterData from "./filter_data";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Backdrop from "@material-ui/core/Backdrop";
import AllCauseGraph from "./admin_cause_clicks_graph";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "1%",
    boxShadow: "1px 0px 20px 5px #7052fb10",
    backgroundColor: "#fff",
    borderRadius: "10px",
    paddingBottom: "50px",
  },
  divider: {
    margin: "3% 0",
  },
  subtitle: {
    padding: "3%",
    fontSize: "25px",
    fontWeight: "bold",
    color: "#000",
  },
  timegraph: {
    minHeight: "250px",
  },
  overview: {
    textAlign: "center",
    padding: "1% 0 3%",
    "& span": {
      fontSize: "2em",
    },
  },
  card1: {
    background: "url('/card_bg.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  card2: {
    background: "url('/card2_bg.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  card3: {
    background: "url('/card3_bg.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

const FILTER_INIT_STATE = {
  user_group: 1,
  evolution: 1,
  age: "100-100",
  gender: 10,
  zipcode: "",
  time_range: "0",
};

function UserDetails(props) {
  const classes = useStyles();
  const user = props.user || JSON.parse(sessionStorage.getItem("user"));
  const [todayStats, setTodayStats] = useState({});
  const [yesterdayStats, setYesterdayStats] = useState({});
  const [overAllStats, setOverallStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [bluetoothClicks, setBluetoothClicks] = useState(0);
  const [filters, setFilters] = useState(FILTER_INIT_STATE);
  const [showAllData, setShowAllData] = useState(!props.match.params.userId);
  const message = useContext(ToastMessageContext);
  const [refresh, setRefresh] = useState(false);
  const [selectEvaluation, setSelectEvaluation] = useState(1);

  useEffect(() => {
    getStatData();
  }, []);

  const getStatData = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    let params = {};
    const userId = props.match.params.userId;
    if (currentUser.role !== "user") {
      params = {
        id: userId || null,
        ...filters,
      };
    }
    setLoading(true);
    DataServices.getData(params, "/user/statistics").then((res) => {
      if (res.success) {
        setTodayStats(res.today);
        setYesterdayStats(res.yesterday);
        setOverallStats(res.overall || {});
        setBluetoothClicks(res.bluetooth_clicks || 0);
        setLoading(false);
      } else {
        setLoading(false);
        message.showToastMessage({
          message: "Something went wrong. Try Again",
          variant: "error",
        });
      }
    });
  };

  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const resetFilter = () => {
    setFilters(FILTER_INIT_STATE);
  };

  const updateFilter = (filterState) => {
    setFilters(filterState);
  };

  const refreshData = () => {
    setRefresh(!refresh);
    getStatData();
  };

  const showGranularData =
    user.role === "user" ||
    ((user.role === "super_admin" || user.role === "admin") && !showAllData);

  const evaluationClick = (e) => {
    setSelectEvaluation(e.target.value);
  };
  return (
    <>
      <div style={{ margin: "3%" }}>
        <UserGreetingMessage user={user} overall={showAllData} />
        {user.role !== "user" && (
          <div style={{ textAlign: "right", paddingTop: "15px" }}>
            <FormControlLabel
              control={
                <Switch
                  color={"primary"}
                  checked={showAllData}
                  onChange={(e) => setShowAllData(e.target.checked)}
                />
              }
              label="All User Data"
              labelPlacement={"start"}
            />
          </div>
        )}
        <div className={classes.divider} />
        {showAllData && user.role !== "user" && (
          <div className={classes.filter}>
            <FilterData
              filters={filters}
              handleFilters={handleFilters}
              resetFilter={resetFilter}
              updateFilter={updateFilter}
              role={user.role}
              refreshData={refreshData}
            />
          </div>
        )}
        <div className={classes.overview}>
          <Typography
            component={"span"}
            color={"primary"}
            variant={"subtitle2"}
          >
            Evolution of
          </Typography>
          <Select
            defaultValue={1}
            labelId="evolution"
            id="evolution"
            name="evolution"
            label={"evolution"}
            placeholder={"evolution"}
            style={{ width: "auto", margin: "0 10px" }}
            onChange={(e) => evaluationClick(e)}
          >
            <MenuItem value={1}>Beta</MenuItem>
            <MenuItem value={2}>Alpha</MenuItem>
            <MenuItem value={3}>Theta</MenuItem>
            <MenuItem value={4}>Delta</MenuItem>
          </Select>
          <Typography component={"span"} variant={"subtitle2"}>
            Overview
          </Typography>
        </div>
        <Grid
          container
          spacing={4}
          alignItems={"flex-start"}
          justify={"center"}
        >
          <Grid item xs={12}>
            <CumulativeStats
              stats={{
                yesterday: yesterdayStats,
                today: todayStats,
                overall: overAllStats,
              }}
              todayStats={todayStats}
              overAllStats={overAllStats}
              bluetoothClicks={bluetoothClicks}
            />
          </Grid>
          {showGranularData ? (
            <>
              <Grid item md={4} xs={12}>
                <div className={`${classes.section} ${classes.card3}`}>
                  <Typography align={"center"} className={classes.subtitle}>
                    Overall
                  </Typography>
                  <ButtonClickStats stats={overAllStats} />
                  <CauseClickStats stats={overAllStats} />
                </div>
              </Grid>
              <Grid item md={4} xs={12}>
                <div className={`${classes.section} ${classes.card1}`}>
                  <Typography align={"center"} className={classes.subtitle}>
                    Yesterday
                  </Typography>
                  <ButtonClickStats stats={yesterdayStats} />
                  <CauseClickStats stats={yesterdayStats} />
                </div>
              </Grid>
              <Grid item md={4} xs={12}>
                <div className={`${classes.section} ${classes.card2}`}>
                  <Typography align={"center"} className={classes.subtitle}>
                    Today
                  </Typography>
                  <ButtonClickStats stats={todayStats} />
                  <CauseClickStats stats={todayStats} />
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <div className={`${classes.section} ${classes.timegraph}`}>
                  <Typography
                    style={{ padding: "10px 25px" }}
                    variant={"subtitle2"}
                    color="primary"
                  >
                    Causes clicked comparison ( Yesterday Vs Today )
                  </Typography>
                  <CauseComparison
                    userId={props.match.params.userId}
                    user={user}
                    stats={{ today: todayStats, yesterday: yesterdayStats }}
                    statsString={`${JSON.stringify(
                      todayStats
                    )}-${JSON.stringify(yesterdayStats)}`}
                  />
                </div>
              </Grid>
            </>
          ) : (
            <Grid container xs={12} md={12} className={`${classes.section}`}>
              <Grid item xs={12} md={3}>
                <CauseClickStats
                  stats={overAllStats}
                  type={"bar"}
                  user={"admin"}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <AllCauseGraph stats={overAllStats} />
              </Grid>
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <div className={`${classes.section} ${classes.timegraph}`}>
              <Typography
                style={{ padding: "10px 25px" }}
                variant={"subtitle2"}
                color="primary"
              >
                All Time Clicks
              </Typography>
              <ClicksTimelineGraph
                userId={props.match.params.userId}
                user={user}
                filters={filters}
                refresh={refresh}
                evaluationKey={selectEvaluation}
              />
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={`${classes.section} ${classes.timegraph}`}>
              <ClicksRawData
                userId={props.match.params.userId}
                user={user}
                filters={filters}
                refresh={refresh}
                evaluationKey={selectEvaluation}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Backdrop open={loading} invisible={true}>
        <div className={"block loader"}>
          <CircularProgress color="primary" />
        </div>
      </Backdrop>
    </>
  );
}

export default UserDetails;
