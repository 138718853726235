import React, { Fragment, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { BASE_API_URL } from "../../../lib/services/constants";

const useStyles = makeStyles((theme) => ({
  paddingNull: {
    padding: "20px 0px",
    minHeight: "calc(100vh - 70px)",
    // background: '#ffffff'
  },
  tableWrapper: {
    width: "100%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

const Edit = (props) => {
  const classes = useStyles();
  const token = sessionStorage.getItem("token") ?? null;
  const location = useLocation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const editData = async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: BASE_API_URL + "/update-content",
        data: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          //handle success
          props.history.push("/admin/tutorial/list");
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (location.state?.data) {
      setValue("title", location.state.data.title);
      setValue("link", location.state.data.link);
      setValue("description", location.state.data.description);
    }
  }, [location.state]);

  const onSubmit = (data) => {
    data.action = "tutorial";
    data.id = location.state.data.id;
    editData(data);
  };
  return (
    <Fragment>
      <Container className={classes.paddingNull}>
        <div className={classes.tableWrapper}>
          <div className={"align-corner"}>
            <Typography
              component={"h2"}
              style={{ width: "50%" }}
              className={"title"}
            >
              Edit Tutorial
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Grid container spacing={4} alignItems={"baseline"}>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: "Title is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"title"}
                        label={"Tutorial title"}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                        //   defaultValue={currentSelection.name}
                      />
                    )}
                  />
                  {errors?.title?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.title?.message}
                    </p>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="link"
                    control={control}
                    rules={{
                      required: "Link is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"link"}
                        label={"Tutorial link"}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                        //   defaultValue={currentSelection.name}
                      />
                    )}
                  />
                  {errors?.link?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.link?.message}
                    </p>
                  )}
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: "Description is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"description"}
                        label={"Description"}
                        multiline
                        minRows={4}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                        //   defaultValue={currentSelection.description}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.description?.message}
                    </p>
                  )}
                </Grid>
              </Grid>
              <div
                style={{ width: "100%", textAlign: "center", margin: "15px 0" }}
              >
                <Button
                  style={{ padding: "10px 20px" }}
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                  // onClick={() => {
                  //   props.history.push('/admin/Tutorial/create')
                  // }}
                >
                  Edit Tutorial
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default Edit;
