import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import UsersIcon from "@material-ui/icons/Person";
import UserIcon from "@material-ui/icons/AccountCircle";
import PasswordIcon from "@material-ui/icons/VpnKey";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/TimelineRounded";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Collapse, Popover } from "@material-ui/core";
import BallotIcon from "@material-ui/icons/Ballot";
import TutorialIcon from "@material-ui/icons/TuneSharp";
import ForumIcon from "@material-ui/icons/ForumSharp";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "2px 0px 15px 0 #7052fb10",
    backgroundColor: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#fff",
  },
  content: {
    flexGrow: 1,
    margin: 0,
    maxWidth: "100vw",
  },
  orgLogo: {
    display: "flex",
    padding: "5px 25px",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      maxHeight: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  navList: {
    "&:hover": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      backgroundColor: "unset",
    },
    "&.Mui-selected": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      backgroundColor: "unset",
      marginLeft: theme.spacing(2),
      "&:hover": {
        backgroundColor: "unset",
      },
      "& .MuiListItemText-primary": {
        fontWeight: "600",
        fontSize: "1.1rem",
      },
    },
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "unset !important",
    },
  },
}));

const withDefaultLayout = (Component) => {
  function ComponentWithLayout(props) {
    const { container, userRole, user } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setUserActionOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const currentPath = props.location.pathname;
    const isCurrentSection = (listValue) => {
      return currentPath.indexOf(listValue) !== -1;
    };

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const userSections = [
      {
        name: "Dashboard",
        url: "/dashboard",
        redirect: "/",
        icon: <DashboardIcon />,
      },
      {
        name: "Message",
        url: "/message",
        redirect: "/message",
        icon: <MailOutlineIcon />,
      },
      {
        name: "Logout",
        url: "/auth/signin",
        redirect: "/auth/signin",
        icon: <LogoutIcon />,
      },
    ];

    const superAdminSections = [
      {
        name: "Dashboard",
        url: "/dashboard",
        redirect: "/",
        icon: <DashboardIcon />,
      },
      {
        name: "Message",
        url: "/message",
        redirect: "/message",
        icon: <MailOutlineIcon />,
      },
      {
        name: "All User",
        url: "/all-user",
        redirect: "/all-user",
        icon: <UsersIcon />,
      },
      {
        name: "New User",
        url: "/new-user",
        redirect: "/new-user",
        icon: <UsersIcon />,
      },
      {
        name: "User Groups",
        url: "/user_groups",
        redirect: "/user_groups",
        icon: <UsersIcon />,
      },
      {
        name: "SM Admins",
        url: "/users/sensamind/admins",
        redirect: "/users/sensamind/admins",
        icon: <AdminIcon />,
      },
    ];

    const groupId =
      JSON.parse(sessionStorage.getItem("user")).user_group?.id || null;
    const adminSections = [
      {
        name: "Dashboard",
        url: "/dashboard",
        redirect: "/",
        icon: <DashboardIcon />,
      },
      {
        name: "Message",
        url: "/message",
        redirect: "/message",
        icon: <MailOutlineIcon />,
      },
      {
        name: "Group",
        url: "/user_groups",
        redirect: "/user_groups",
        icon: <UsersIcon />,
      },
      {
        name: "Users",
        url: `/users/${groupId || ""}`,
        redirect: `/users/${groupId || ""}`,
        icon: <AdminIcon />,
      },
    ];

    const sectionMap = {
      super_admin: superAdminSections,
      admin: adminSections,
      user: userSections,
    };
    const sections = sectionMap[userRole] || userSections;

    const drawer = (
      <div>
        <div className={`${classes.orgLogo} ${classes.toolbar}`}>
          <img src={"/sensamind.png"} alt={"Sensamind Logo"} />
        </div>
        <List className={classes.list}>
          {sections.map((sec, i) => (
            <ListItem
              className={classes.navList}
              key={i}
              button
              selected={isCurrentSection(sec.url)}
              onClick={() => props.history.push(sec.redirect)}
            >
              <ListItemIcon style={{ color: "inherit" }}>
                {sec.icon}
              </ListItemIcon>
              <ListItemText>{sec.name}</ListItemText>
            </ListItem>
          ))}
          <ListItem
            className={classes.navList}
            button
            onClick={() => props.history.push(`/auth/change-password/by_link`)}
          >
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </ListItem>
          <ListItem
            className={classes.navList}
            button
            onClick={() => props.history.push("/auth/signin")}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </div>
    );

    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.orgLogo}>
              <img src={"/sensamind.png"} alt={"Sensamind Logo"} />
            </div>
            <div className={classes.grow} />
            <Hidden smDown implementation="css">
              <Grid
                container
                justify="space-around"
                alignItems="center"
                spacing={4}
              >
                {sections &&
                  sections.map((section, i) => {
                    return (
                      <Grid item key={i}>
                        <Button
                          fullWidth={true}
                          className={classes.button}
                          style={
                            props.location.pathname.indexOf(section.url) >= 0
                              ? {
                                  color: "#7052fb",
                                }
                              : {}
                          }
                          startIcon={section.icon}
                          onClick={() => props.history.push(section.redirect)}
                        >
                          {section.name}
                        </Button>
                      </Grid>
                    );
                  })}
                <Grid item>
                  <Button
                    fullWidth={true}
                    className={classes.button}
                    startIcon={<UserIcon />}
                    onClick={(e) => {
                      setUserActionOpen(!open);
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    {user.email}
                  </Button>
                </Grid>
                <Popover
                  id={"user_action"}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setUserActionOpen(false)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      minWidth: "100px",
                      padding: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* {currentUser.role == "super_admin" ? (
                      <Button
                        className={classes.button}
                        startIcon={<BallotIcon />}
                        onClick={() => props.history.push(`/admin/blog/list`)}
                      >
                        Blog
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        startIcon={<BallotIcon />}
                        onClick={() => props.history.push(`/user/blog`)}
                      >
                        Blog
                      </Button>
                    )} */}
                    {/* tutorila */}
                    {currentUser.role == "super_admin" ? (
                      <Button
                        className={classes.button}
                        startIcon={<TutorialIcon />}
                        onClick={() =>
                          props.history.push(`/admin/tutorial/list`)
                        }
                      >
                        Tutorial
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        startIcon={<TutorialIcon />}
                        onClick={() => props.history.push(`/user/tutorial`)}
                      >
                        Tutorial
                      </Button>
                    )}
                    {/* forum */}
                    {/* {currentUser.role == "super_admin" ? (
                      <Button
                        className={classes.button}
                        startIcon={<ForumIcon />}
                        onClick={() => props.history.push(`/admin/forum/list`)}
                      >
                        Forum
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        startIcon={<ForumIcon />}
                        onClick={() => props.history.push(`/user/forum`)}
                      >
                        Forum
                      </Button>
                    )} */}
                    {/* end */}
                    <Button
                      className={classes.button}
                      startIcon={<PasswordIcon />}
                      onClick={() =>
                        props.history.push(`/auth/change-password/by_link`)
                      }
                    >
                      Change Password
                    </Button>
                    <Button
                      className={classes.button}
                      startIcon={<LogoutIcon />}
                      onClick={() => props.history.push("/auth/signin")}
                    >
                      Logout
                    </Button>
                  </div>
                </Popover>
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Hidden smUp implementation="css">
          <nav className={classes.drawer} aria-label="Pages">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Hidden>
        <main className={classes.content} id={"main_content"}>
          <div className={classes.toolbar} />
          <Component {...props} showSideBar={handleDrawerToggle} />
        </main>
      </div>
    );
  }
  return ComponentWithLayout;
};

export default withDefaultLayout;
