import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { BASE_API_URL } from "../../../lib/services/constants";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import "./message.scss";

const useStyles = makeStyles((theme) => ({
  paddingNull: {
    padding: "0px",
    minHeight: "100vh",
    background: "#ffffff",
  },
}));

const Message = () => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [singleUser, setSingleUser] = useState(null);
  const [singleUserSMS, setSingleUserSMS] = useState([]);
  const [fileShow, setFileShow] = useState(null);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));

  const token = sessionStorage.getItem("token") ?? null;

  // user list data fetch
  const fetchUserList = async () => {
    try {
      const response = await axios(BASE_API_URL + "/user-chat-list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data?.status === true) {
        setUserList(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // search user
  const fetchSearchUserList = async (name) => {
    try {
      const response = await axios(BASE_API_URL + "/search-user", {
        params: {
          name: name,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data?.status === true) {
        setUserList(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserChatList = async (id) => {
    try {
      const response = await axios(BASE_API_URL + "/sing-user-message/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data?.status === true) {
        setSingleUserSMS(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendUserSMS = async (data) => {
    try {
      let bodyFormData = new FormData();
      bodyFormData.append("message", data.message);
      bodyFormData.append("receiver_id", data.receiver_id);
      if (data?.file) {
        bodyFormData.append("attachment", data.file);
      }
      const response = await axios({
        method: "post",
        url: BASE_API_URL + "/send-message",
        data: bodyFormData,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          //handle success
          fetchUserChatList(response.data?.data?.group_id);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

      if (response.data?.status === true) {
        setSingleUserSMS(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const userDeatilGet = (u) => {
    // console.log(u);
    fetchUserChatList(u?.chat_group_id);
    setSingleUser(u);
  };
  const messageData = (e) => {
    e.preventDefault();
    if (singleUser === null) {
      alert("User select first");
    } else {
      let sendData = {
        message: e.target.send.value,
        receiver_id: singleUser?.id,
        file: fileShow,
      };
      sendUserSMS(sendData);
      setFileShow(null);
    }
    e.target.reset();
  };

  const userListSearch = (e) => {
    if (e.target.value === "") {
      fetchUserList();
    } else {
      fetchSearchUserList(e.target.value);
    }
  };

  const uploadFileClick = (e) => {
    setFileShow(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false} className={classes.paddingNull}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <LeftSide
                userList={userList}
                userDetailsClick={(u) => userDeatilGet(u)}
                searchUser={(e) => userListSearch(e)}
                userRole={currentUser.role}
              />
            </Grid>
            <Grid item xs={8}>
              <RightSide
                userDetails={singleUser}
                messageSend={(e) => messageData(e)}
                smsList={singleUserSMS}
                fileState={fileShow}
                hideFileClick={() => {
                  setFileShow(null);
                }}
                fileSelect={(e) => uploadFileClick(e)}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Message;
