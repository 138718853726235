import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import tableIcons, {
  options,
} from "../../components/universal/table_attributes";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import axios from "axios";
import moment from "moment";
import { BASE_API_URL } from "../../lib/services/constants";

const useStyles = makeStyles((theme) => ({
  paddingNull: {
    padding: "20px 0px",
    minHeight: "calc(100vh - 70px)",
    // background: '#ffffff'
  },
  tableWrapper: {
    width: "100%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

const AdminBlogList = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState();
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);

  const [dataList, setDataList] = useState([]);
  const token = sessionStorage.getItem("token") ?? null;

  const tableOptions = {
    ...options,
    page: page || 1,
    total: total,
    pageSize: pageSize,
    searchText: searchText,
  };

  const columns = [
    {
      title: "SL",
      field: "id",
      width: "25%",
      headerStyle: {
        paddingLeft: "3%",
      },
      cellStyle: {
        paddingLeft: "3%",
      },
      render: (rowData) => <span>{rowData.id || "-"}</span>,
    },
    {
      title: "Title",
      field: "title",
      width: "55%",
      render: (rowData) => <span>{rowData.title || "-"}</span>,
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => <span>{rowData.description || "-"}</span>,
    },
    {
      title: "Created At",
      field: "created_at",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <span>
          {moment(rowData.created_at).format("MM/DD/YYYY hh:mm A") || "-"}
        </span>
      ),
    },
  ];

  const fetchDataList = async () => {
    try {
      const response = await axios(
        BASE_API_URL + "/get-all-content?action=blog",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data?.status === true) {
        setLoading(false);
        setDataList(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // api call
  useEffect(() => {
    setLoading(true);
    fetchDataList();
  }, []);

  return (
    <Fragment>
      <Container className={classes.paddingNull}>
        <div className={classes.tableWrapper}>
          <div className={"align-corner"}>
            <Typography
              component={"h2"}
              style={{ width: "50%" }}
              className={"title"}
            >
              All Blog list
            </Typography>
            <div
              style={{ width: "100%", textAlign: "right", margin: "15px 0" }}
            >
              <Button
                style={{ marginLeft: "16px" }}
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  props.history.push("/admin/blog/create");
                }}
              >
                Add New Blog
              </Button>
            </div>
          </div>
          <MaterialTable
            style={{ boxShadow: "1px 0px 20px 5px #7052fb10" }}
            icons={tableIcons}
            title={""}
            columns={columns}
            data={dataList}
            isLoading={loading}
            options={tableOptions}
            localization={{
              toolbar: {
                searchPlaceholder: "Search by name",
              },
              body: {
                emptyDataSourceMessage: "No user groups found",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
              header: {
                actions: "Actions",
              },
            }}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              setPageSize(pageSize);
            }}
            totalCount={+total}
            page={+page}
            actions={[
              {
                icon: () => <EditIcon color={"disabled"} />,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  props.history.push({
                    // no need
                    pathname: "/admin/blog/edit/" + rowData.id,
                    state: { data: rowData },
                  });
                },
              },
              // {
              //   icon: () => <DeleteOutline color={'disabled'} />,
              //   tooltip: 'Delete'
              //   onClick: (event, rowData) => {
              //     props.history.push(`/users/${rowData.id}`)
              //   }
              // }
            ]}
          />
        </div>
      </Container>
    </Fragment>
  );
};

export default AdminBlogList;
