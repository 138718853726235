import React, { useContext, useState } from "react";
import ManageDataDialog from "../../components/universal/dialog_component";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DataServices from "../../lib/services/api/data_services";
import { ToastMessageContext } from "../../lib/contexts/message_context";

const AllGroupUserNotificationMessage = (props) => {
  const { handleClose, groupID } = props;
  const [loading, setLoading] = useState(false);
  const message = useContext(ToastMessageContext);

  const handleSubmit = (formData) => {
    setLoading(true);
    formData["group_id"] = groupID;

    DataServices.postData(
      formData,
      "/admin/send-notification-specific-group"
    ).then((res) => {
      if (res.success) {
        message.showToastMessage({
          message: "Notification Sent Successfully",
          variant: "success",
        });
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        message.showToastMessage({
          message: res.error ? res.error : "Something went wrong. Try again",
          variant: "error",
        });
      }
    });
  };

  return (
    <ManageDataDialog
      title={"Send All Users Notification"}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      type={"Send >"}
    >
      <Grid container spacing={4} alignItems={"center"} justify={"center"}>
        <Grid item xs={12}>
          {/* <TextField
            name={"title"}
            label={"Notification Title"}
            required
            fullWidth
            inputProps={{ minLength: 3, maxLength: 56 }}
            variant="outlined"
          /> */}
          <TextField
            name={"body"}
            label={"Notification Message"}
            required
            fullWidth
            rows={5}
            inputProps={{ minLength: 5, maxLength: 156 }}
            multiline
            variant="outlined"
          />
        </Grid>
      </Grid>
    </ManageDataDialog>
  );
};

export default AllGroupUserNotificationMessage;
