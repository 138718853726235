import React, { Fragment, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { BASE_API_URL } from "../../../lib/services/constants";

const useStyles = makeStyles((theme) => ({
  paddingNull: {
    padding: "20px 0px",
    minHeight: "calc(100vh - 70px)",
  },
  tableWrapper: {
    width: "100%",
    margin: "2%",
  },
  evolWrapper: {
    width: "100%",
    margin: "2%",
  },
}));

const Create = (props) => {
  const classes = useStyles();
  const token = sessionStorage.getItem("token") ?? null;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const sendData = async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: BASE_API_URL + "/create-content",
        data: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (response) {
          //handle success
          props.history.push("/admin/forum/list");
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = (data) => {
    data.action = "forum";
    sendData(data);
  };
  return (
    <Fragment>
      <Container className={classes.paddingNull}>
        <div className={classes.tableWrapper}>
          <div className={"align-corner"}>
            <Typography
              component={"h2"}
              style={{ width: "50%" }}
              className={"title"}
            >
              Create New Forum
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Grid container spacing={4} alignItems={"baseline"}>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: "Title is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"title"}
                        label={"Forum title"}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                      />
                    )}
                  />
                  {errors?.title?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.title?.message}
                    </p>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="link"
                    control={control}
                    rules={{
                      required: "Link is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"link"}
                        label={"Forum link"}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                      />
                    )}
                  />
                  {errors?.link?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.link?.message}
                    </p>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="sector"
                    control={control}
                    rules={{
                      required: "Evolution is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        labelId="evolution"
                        id="evolution"
                        name="sector"
                        label={"evolution"}
                        placeholder={"evolution"}
                        value={value}
                        onChange={onChange}
                        style={{
                          width: "100%",
                          padding: "10px 12px",
                          borderRadius: "4px",
                          border: "1px solid #bfbfc3",
                          borderBottom: "unset",
                          color: "#727275",
                        }}
                      >
                        <MenuItem value={1}>Beta</MenuItem>
                        <MenuItem value={2}>Alpha</MenuItem>
                        <MenuItem value={3}>Theta</MenuItem>
                        <MenuItem value={4}>Delta</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.evolution?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "5px 0 0 0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.evolution?.message}
                    </p>
                  )}
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: "Description is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        name={"description"}
                        label={"Description"}
                        multiline
                        minRows={4}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: "5px" }}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <p
                      style={{
                        color: "rgb(95, 33, 32)",
                        backgroundColor: "rgb(253, 237, 237)",
                        padding: "7px 20px",
                        margin: "0",
                        borderRadius: "5px",
                      }}
                    >
                      {errors?.description?.message}
                    </p>
                  )}
                </Grid>
              </Grid>
              <div
                style={{ width: "100%", textAlign: "center", margin: "15px 0" }}
              >
                <Button
                  style={{ padding: "10px 20px" }}
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                >
                  Add Forum
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default Create;
